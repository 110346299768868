.social-media {
    display: flex;
    justify-content: center;
    gap: 20px;
    /* Adjust spacing between icons */
    margin-top: 10px;
}

.icon {
    font-size: 45px;
    /* Adjust size to make icons bigger */
    color: #ffffff;
    /* Set default color */
    transition: transform 0.3s, color 0.3s;
    /* Smooth hover effects */
    cursor: pointer;
}

.icon:hover {
    transform: scale(1.2);
    /* Slightly enlarge icon on hover */
    color: #7289da;
    /* Change color on hover */
}