@font-face {
    font-family: 'Solaria';
    src: url('../../assets/fonts/Solaria.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
}


.footer {

    display: flex;
    width: 100%;
    flex-direction: column;
    font-family: 'Solaria';
    padding-top: 40px;
}

.footer h1 {
    color: grey;
}

.footer img {
    height: 50px;
    width: 50px;

}