/* About.css */

.about-container {
    background-color: #1e1e1e;
    /* Dark background */
    color: #f0f0f0;
    /* Light text color */
    padding: 40px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    /* Center the container */
    border-radius: 8px;
    margin-top: 10vh;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.about-container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #00bcd4;
    /* Accent color for the heading */
    text-align: center;
}

.about-container .intro {
    font-size: 1.5em;
    margin-bottom: 30px;
    text-align: center;
}

.about-container p {
    font-size: 1.1em;
    line-height: 1.8;
    margin-bottom: 20px;
    text-align: justify;
}

.about-container p:last-child {
    margin-bottom: 0;
}

@media (max-width: 600px) {
    .about-container {
        padding: 20px;
    }

    .about-container h1 {
        font-size: 2em;
    }

    .about-container .intro {
        font-size: 1.3em;
    }

    .about-container p {
        font-size: 1em;
    }
}