header {
    text-align: center;
    margin: 0;
    padding-top: 20px;

}

header h1 {
    margin: 0;
    color: white;
    font-family: 'Solaria';
}

.navigation button {
    margin: 10px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    color: black;
    border: 1px solid #e0e0e0;
    /* Lighter color for border */
    border-radius: 4px;
    transition: transform 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.navigation button:hover {
    transform: scale(1.05);
    color: #fcfcfc;
    background-color: rgba(255, 255, 255, 0.1);
    /* Light translucent background on hover */
}