/* Chat container */
.chatContainer {
    position: relative;
    width: 40vw;
}

.chatContainer h5 {
    margin: 10px;
    color: white;
}

.chatContainer h4 {
    margin: 10px;
    color: white;
}

.close-gif-search {
    align-self: flex-end;
    cursor: pointer;
    font-size: 24px;
    /* Adjust as needed */
    color: #fff;
    /* Change to desired color */
    margin-bottom: 10px;
    /* Optional: add space below the icon */
    /* Remove position: relative; and top: 10px; */
}

.gif-search {
    position: relative;
    /* Add your existing styles for .gif-search */
}

.chat-button-container {
    display: flex;
    gap: 10px;
    margin: 10px;
    /* Space between buttons */
}

.chat-icon {
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.2s;
}

.chat-icon:hover {
    transform: scale(1.1);
    /* Slight zoom effect on hover */
}

#input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #555;
    background-color: #1e1e1e;
    color: #f0f0f0;
    margin-bottom: 10px;
    margin: 0;
    /* Add margin to separate input from buttons */
}

input:focus {
    outline: 2px solid #7289da;
    /* Customize color and style */
    box-shadow: 0 0 5px #7289da;
}

/* Chat area with border and fixed size */
#chatArea {
    height: 500px;
    border: 2px solid #444;
    padding: 10px;
    background-color: #1e1e1e;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Courier New', Courier, monospace;
    filter: blur(4px);
    pointer-events: none;
    transition: filter 0.3s ease;
    color: #f0f0f0;
    overflow: hidden;
    /* Keep overflow hidden */
}

/* Username prompt overlay */
#usernamePrompt {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    color: #fff;
}

#messages {
    padding: 0;
    margin: 0;
    flex-grow: 1;
    max-height: 500px;
    height: 400px;
    overflow-y: auto;
    /* Enable scrolling */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* Adjust alignment if needed */
    color: #e1e1e1;
}

/* Style for clickable links in chat messages */
#messages a {
    color: #ADD8E6;
    /* Light blue color */
    text-decoration: none;
    /* Remove underline */
}

#form {
    display: flex;
    flex-direction: row;
    background-color: #2b2b2b;
    border-top: 1px solid #444;
    padding: 10px;
}

#messages img {
    width: 200px;
    height: 200px;
}


#messages a:hover {
    text-decoration: underline;
    /* Underline on hover for better visibility */
}

/* Each message spacing */
#messages li {
    margin-bottom: 10px;
    text-align: left;
    /* Align text to the left */
    list-style-type: none;
    /* Remove bullets from list */
    background-color: #333;
    /* Dark background for messages */
    padding: 8px;
    border-radius: 5px;
    color: #dcdcdc;
}

/* Form at the bottom of the chat area */
#form {
    display: flex;
    background-color: #2b2b2b;
    /* Dark background for input area */
    border-top: 1px solid #444;
    padding: 10px;
}

/* Input field styling */
#input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #555;
    background-color: #1e1e1e;
    color: #f0f0f0;
}

/* Button styling */
.chat-button {
    padding: 10px;
    background-color: #555;
    color: #fff;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.3s ease;
}

/* Button hover effect */
.chat-button:hover {
    background-color: #888;
}

/* Additional styling for other buttons */
button:hover {
    background-color: #333;
    color: #f0f0f0;
}


#username {
    padding: 10px;
    font-size: 16px;
    background-color: #2b2b2b;
    /* Dark background color */
    color: #f0f0f0;
    /* Light text color */
    border: 1px solid #555;
    /* Subtle border */
    border-radius: 5px;
    /* Rounded corners */
    outline: none;
    /* Remove default outline */
    transition: border-color 0.3s ease;
    /* Smooth border transition */
}

#username::placeholder {
    color: #888;
    /* Lighter color for placeholder text */
}

#username:focus {
    border-color: #888;
    /* Change border color on focus */
}


/* GIF search window */
.gif-search {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 80px;
    /* Adjusted to make the popup open upwards */
    background: #222;
    padding: 10px;
    border-radius: 5px;
    z-index: 100;
    width: 300px;
    max-width: 300px;
    /* Add box-shadow for a subtle popup effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    right: 1%;

}

/* GIF results styling */
.gif-results {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow-y: scroll;
    max-height: 300px;
}

.gif-results img {
    width: 80px;
    /* Smaller size for GIFs */
    height: 80px;
    margin: 5px;
    cursor: pointer;
    object-fit: cover;
    border-radius: 4px;
    transition: transform 0.2s;
}

.gif-results img:hover {
    transform: scale(1.1);
    /* Zoom effect on hover */
}

/* Styling for the GIF search input */
.gif-search input {
    width: 80%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #444;
    background-color: #1e1e1e;
    color: #f0f0f0;
    border-radius: 4px;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
    .chatContainer {
        width: 80vw;
    }

    .gif-search {
        bottom: 70px;
        /* Adjust bottom position for smaller screens */
        max-width: 100%;
        /* Full width for mobile */
    }
}